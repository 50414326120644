@import url('./fonts/font.css');

body {
  margin: 0;
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("../public/images/main.png") no-repeat center 15% #171820;
  background-size: 65%;
  font-size: 16px;
  line-height: 24px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grecaptcha-badge { visibility: hidden; }
